<template>
  <div class="white--text grey lighten-3 pa-0">
    <simple-header hasLeftChild :routes="routes" :title="routes[1].title">
      <v-icon dark size="150" class="float-left">mdi-information</v-icon>
    </simple-header>
    <v-container>
      <v-card class="rounded-xl moholand-shadow">
        <v-card-text class="pa-8 black--text">
          <h1 v-if="details.name" class="text-center mb-8">
            {{ details.name }}
          </h1>
          <div v-if="details.desc">
            <span v-html="details.desc" class="fix-html-images"/>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import MyAxios from "@/constants/MyAxios";
import MyDataTable from "../../components/MyDataTable.vue";
import VFormBase from "vuetify-form-base";
import SimpleHeader from "../../components/SimpleHeader.vue";

export default {
  name: "Payment",
  components: {
    MyDataTable,
    VFormBase,
    SimpleHeader,
  },
  data() {
    return {
      routes: [{ title: "خانه", url: "/" }],
      slug: this.$route.params.slug || "",
      loading: false,
      details: {},
    };
  },
  created() {
    this.getData();
  },
  watch: {
    "$route.params"(val) {
      console.log(val);
      this.slug = val.slug || "";
      this.getData();
    },
  },
  methods: {
    getData() {
      this.$root.$emit("globalLoadingSet", true);
      MyAxios.get("pages/index/" + this.slug, {
        params: {
          noPaginate: true,
        },
      })
        .then((response) => {
          console.log(response.data);
          this.details = response.data[0];
          this.routes.push({ title: response.data[0].name });
          this.$root.$emit("globalLoadingSet", false);
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("toast", {
            text: error.response.data.msg,
            type: "error",
          });
          this.$root.$emit("globalLoadingSet", false);
        });
    },
  },
};
</script>

<style scoped>
.sp-img {
  /* width: 20em;
    height: 20em; */
  max-width: 150px;
  max-height: 100px;
}
</style>
